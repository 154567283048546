<template>
  <div class="about-us-page">
    <section class="au-first">
      <div class="container">
        <div class="au-s-links">
          <div class="text">
            <router-link to="/">{{ $t("Bosh sahifa") }}</router-link>
            <span>{{ $t("News") }}</span>
          </div>
          <share></share>
        </div>
        <div class="au-texts">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="about-us">
                <div class="title">{{ post.title }}</div>
                <div class="sub">{{ post.anons }}</div>
                <div class="sub">
                  <div v-html="post.description"></div>
                </div>
                <div class="n-data" v-if="post !== ''">
                  <img src="../../../assets/images/icons/time.svg" alt="" />
                  {{monthFormat(post.updated_at*1000)}}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="my-news-cards">
                <div class="row" v-if="posts.length > 0">
                  <div
                    class="col-lg-12 col-md-4 col-sm-6 col-10 mar-bot"
                    v-for="post in posts"
                    :key="post.id"
                  >
                    <news-card
                      :title="post.title"
                      :anons="post.anons"
                      :date="post.updated_at"
                      :slug="post.slug"
                      :postId="post.id"
                    >
                    </news-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <mini-cards></mini-cards>
      </div>
    </section>
    <contact-us></contact-us>
  </div>
</template>

<script>
import contactUs from "../../elements/ContactUs";
import miniCards from "../../elements/MiniCards";
import newsCard from "../../elements/NewsCard.vue";
import Share from "../../elements/Share.vue";
import { mapState } from "vuex";
import { monthFormat } from '@/utils/index'
export default {
  components: {
    contactUs,
    miniCards,
    newsCard,
    Share,
  },
  computed: {
    ...mapState(["posts", "post"]),
  },
  methods : {
    monthFormat,
  },
  mounted() {
    this.$store.dispatch("postAction");
    this.$store.dispatch("getPost", this.$route.params.id);
    console.log(this.$route.params.id);
  },
};
</script>

<style>
</style>